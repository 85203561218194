<!-- 教材详情 -->
<template>
  <div
    v-loading.fullscreen.lock="fullscreenLoading"
    class="view-teaching-material"
  >
    <div class="book-title">
      <div class="title">
        <WTGoBack />
        <span>
          {{ teachingMaterialInfo.editionName
          }}{{ teachingMaterialInfo.gradeName
          }}{{ getLabelByValue(teachingMaterialInfo.subjectId, subjectOptions)
          }}{{ teachingMaterialInfo.name }}
        </span>
      </div>
    </div>

    <div class="book-content">
      <div class="info" @click="showAllBookImages">
        <div class="info-cover">
          <img :src="coverPicture" alt="教材封面" />
        </div>
        <div class="info-item">
          {{ teachingMaterialInfo.editionName
          }}{{ teachingMaterialInfo.gradeName
          }}{{ getLabelByValue(teachingMaterialInfo.subjectId, subjectOptions)
          }}{{ teachingMaterialInfo.name }}
        </div>
        <div class="info-item">
          版本：{{ teachingMaterialInfo.editionName }}
        </div>
        <div class="info-item">
          科目：{{
            getLabelByValue(teachingMaterialInfo.subjectId, subjectOptions)
          }}
        </div>
        <!-- <div class="info-item">学段：{{ teachingMaterialInfo.gradeName }}</div> -->
        <div class="info-item">年级：{{ teachingMaterialInfo.gradeName }}</div>
        <div class="info-item">
          章节：{{ teachingMaterialChapters.length + "章节" }}
        </div>
        <el-button
          :key="pageKey"
          class="info-btn"
          :loading="downloadBook"
          @click="download()"
          >下载本套教材PDF电子书</el-button
        >
        <!-- <div class="info-btn">教材纠错</div> -->
      </div>
      <div class="cover">
        <!-- <img :src="coverPicture" alt="教材封面" /> -->
        <div
          v-for="(item, key) in bookImgs"
          :key="key"
          class="img-list"
          @click="previewBook"
        >
          <el-image
            style="
              width: 100%;
              height: 100%;
              border: solid 1px #e0e0e0;
              border-radius: 4px;
            "
            :src="item"
            :preview-src-list="bookImgs"
          >
          </el-image>
        </div>
      </div>
      <div class="chapters">
        <div
          v-for="(item, key) in teachingMaterialChapters"
          :key="key"
          class="chapter-item"
          :class="{
            'chapter-header': item.rank == 1,
            'mt-0': item.rank == 3,
            'mt-10': item.rank != 3 && key != 0,
          }"
          @click="showChapterDetail(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import WTGoBack from "@/components/GoBack.vue";
import { getTeachBookInfo } from "@/core/api/resource/resource";
import { updateDownloadCount } from "@/core/api/resource/myResource";
import { subjectOptions } from "@/core/util/constdata";
export default {
  name: "TeachingMaterialDetail",
  components: {
    WTGoBack,
  },
  data() {
    return {
      CDN: "https://static.wtjy.com/",
      teachingMaterialId: 0, // 教材ID
      teachingMaterialInfo: {},
      teachingMaterialChapters: [],
      coverPicture: "", //封面图,每页图片命名: CDN+res/book/{book_id}/img/img_index.png
      subjectOptions: subjectOptions,
      bookImgs: [], //教材图片数组
      fullscreenLoading: false,
      isViewChapter: false,
      pageKey: 0,
      downloadBook: false,
    };
  },
  created() {
    this.getBasePageData();
  },
  methods: {
    dataIsEmpty(field) {
      if (typeof field === "undefined" || field === null || field === "") {
        return true;
      } else {
        return false;
      }
    },
    getLabelByValue(value, data) {
      let label = "";
      data.forEach((item) => {
        if (item.value === value) {
          label = item.label;
        }
      });
      return label;
    },
    getBasePageData() {
      if (!this.$route.query.id || parseInt(this.$route.query.id || 0) <= 0) {
        return;
      }
      this.fullscreenLoading = true;
      this.teachingMaterialId = parseInt(this.$route.query.id || 0);
      getTeachBookInfo(this.teachingMaterialId).then((response) => {
        this.teachingMaterialInfo = response.data.data.resBook;
        this.teachingMaterialChapters = response.data.data.resChapters;
        this.fullscreenLoading = false;
      });
      this.$nextTick(() => {
        this.coverPicture =
          this.CDN + "res/book/" + this.teachingMaterialId + "/img/1.png";
        this.bookImgs.push(this.coverPicture);
      });
    },
    showAllBookImages() {
      this.isViewChapter = false;
      this.previewBook();
    },
    previewBook() {
      if (this.isViewChapter) {
        //
      } else {
        let maxPage = this.teachingMaterialInfo.countImg;
        let imgArr = [];
        let i = 1;
        for (; i <= maxPage; i++) {
          let img_index =
            this.CDN +
            "res/book/" +
            this.teachingMaterialId +
            "/img/" +
            i +
            ".png";
          imgArr.push(img_index);
        }
        this.bookImgs = imgArr;
      }
    },
    showChapterDetail(chapter) {
      this.isViewChapter = true;
      let _start = chapter.startIndex;
      let _end = chapter.endIndex;
      if (_start == 0) {
        _start = 1;
        _end = this.getMaxEndIndex();
      }
      let imgArr = [];
      let i = _start;
      for (; i <= _end; i++) {
        let img_index =
          this.CDN +
          "res/book/" +
          this.teachingMaterialId +
          "/img/" +
          i +
          ".png";
        imgArr.push(img_index);
      }
      this.bookImgs = imgArr;
      // this.bookImgs = this.getAllPreviewImgs(_start, _end);
    },
    getMaxEndIndex() {
      let max = 1;
      this.teachingMaterialChapters.forEach((item) => {
        if (item.endIndex > max) {
          max = item.endIndex;
        }
      });
      return max;
    },

    download() {
      this.pageKey = new Date().getTime();
      this.downloadBook = true;
      this.downloadExamPaper(this.teachingMaterialId);
    },

    downloadExamPaper() {
      if (
        this.teachingMaterialInfo.filePath == null ||
        this.teachingMaterialInfo.filePath.length === 0
      ) {
        this.$message.error("教材文件不存在！");
        return;
      }
      // 下载试卷增加试卷的下载数
      updateDownloadCount(this.teachingMaterialId);
      let download_name =
        this.teachingMaterialInfo.editionName +
        this.teachingMaterialInfo.gradeName +
        this.teachingMaterialInfo.name;
      this.downloadRequest(
        this.CDN + this.teachingMaterialInfo.filePath,
        download_name
      );
    },
    downloadRequest(url, name) {
      let request = new XMLHttpRequest();
      request.open("GET", url, true);
      request.responseType = "blob";
      request.onload = function () {
        let url = window.URL.createObjectURL(request.response);
        let a = document.createElement("a");
        a.href = url;
        a.download = name;
        a.click();
      };
      request.onreadystatechange = () => {
        if (request.readyState == 4 && request.status === 200) {
          this.downloadBook = false;
          this.pageKey = new Date().getTime();
        } else {
          this.downloadBook = true;
        }
      };
      request.send();
    },
  },
};
</script>
<style scoped lang="scss">
.view-teaching-material {
  width: 1220px;
  margin: 0 auto 48px auto;
  .book-title {
    display: flex;
    justify-content: space-between;
    box-shadow: inset 0px -1px 0px #dddddd;
    padding-right: 290px;
    .title {
      padding: 16px 0;
      font-size: 16px;
      text-align: left;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .book-content {
    margin: 30px auto 0;
    width: 1152px;
    display: flex;
    // justify-content: space-between;
    .info {
      width: 198px;
      .info-cover {
        width: 180px;
        height: 260px;
        padding: 15px 10px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .info-item {
        margin-left: 22px;
        color: rgba(0, 0, 0, 0.5);
        &:not(:first-child) {
          margin-bottom: 10px;
        }
      }
      .info-btn {
        border-radius: 4px;
        padding: 10px 0;
        text-align: center;
        color: #ffffff;
        width: 198px;
        height: 40px;
        cursor: pointer;
        // &:not(:last-child) {
        background-color: #2474ed;
        //   margin-bottom: 24px;
        // }
        // &:last-child {
        //   background-color: rgba(0, 0, 0, 0.85);
        // }
      }
    }
    .cover {
      width: 500px;
      // height: 750px;
      margin: 0 32px 0 52px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .chapters {
      min-width: 100px;
      .chapter-item {
        color: rgba(0, 0, 0, 0.5);
        font-weight: 400;
        cursor: pointer;
      }
      .chapter-header {
        color: #2474ed;
      }
      .mt-10 {
        margin-top: 10px;
      }
      .mt-0 {
        margin-top: unset;
      }
    }
  }
}
</style>
